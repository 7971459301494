<div class="main">
    <mat-drawer-container>
        <mat-drawer
            [mode]="drawerMode"
            [opened]="opened"
            (closedStart)="opened = false"
        >
            <div class="sidenav-container">
                <div class="logo-box">
                    <img src="assets/images/logo.svg" alt="" srcset="" />
                    <!-- <h1 class="app-name">{{ "app_name" | translate }}</h1>
                    <span class="divider"></span> -->
                </div>
                <div class="menu-wrapper">
                    <mat-list class="main-menu custom-main-menu">
                        <!-- dashboard -->
                        @if (menu.dashboard?.length > 0) {
                        <mat-list-item
                            matRipple
                            [routerLink]="menu.dashboard[0].route"
                            routerLinkActive="active-route"
                        >
                            <mat-icon svgIcon="home"></mat-icon>
                            <span>{{
                                "routes." + menu.dashboard[0].title | translate
                            }}</span>
                        </mat-list-item>
                        }

                        <!-- expense record -->
                        @if (menu.expense_record?.length > 0) {
                        <mat-list-item
                            matRipple
                            [routerLink]="menu.expense_record[0].route"
                            routerLinkActive="active-route"
                        >
                            <mat-icon svgIcon="record"></mat-icon>
                            <span>{{
                                "routes." + menu.expense_record[0].title
                                    | translate
                            }}</span>
                        </mat-list-item>

                        <!-- settings -->
                        } @if(menu.settings?.length > 0){
                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon svgIcon="settings"></mat-icon>
                                    <span>{{
                                        "route_types." + menu.settings[0].type
                                            | translate
                                    }}</span>
                                </mat-panel-title></mat-expansion-panel-header
                            >
                            @for (item of menu.settings; track $index) {
                            <mat-list-item
                                matRipple
                                class="expand-panel-item"
                                [routerLink]="item.route"
                                routerLinkActive="active-route"
                            >
                                <span>{{
                                    "routes." + item.title | translate
                                }}</span>
                            </mat-list-item>
                            }
                        </mat-expansion-panel>

                        }

                        <!-- user -->
                        @if (menu.users?.length > 0) {
                        <mat-list-item
                            matRipple
                            [routerLink]="menu.users[0].route"
                            routerLinkActive="active-route"
                        >
                            <mat-icon>group</mat-icon>
                            <span>{{
                                "routes." + menu.users[0].title | translate
                            }}</span>
                        </mat-list-item>
                        }
                    </mat-list>
                </div>
            </div>
        </mat-drawer>
        <mat-drawer-content>
            <mat-toolbar>
                <mat-icon
                    (click)="toggleMenu()"
                    color="primary"
                    class="menu-toggle"
                    [class.flipped]="!opened"
                    >menu_open</mat-icon
                >
                <div
                    class="container-fluid align-items-end d-flex flex-row justify-content-end"
                >
                    <!-- languages switcher -->
                    <div
                        class="language-box d-flex flex-row align-items-center"
                        [matMenuTriggerFor]="languageMenu"
                        title="Languages"
                    >
                        <div class="d-flex flex-column">
                            <div class="flag">
                                <ng-container
                                    *ngIf="
                                        currentLanguage === 'English';
                                        else englishTemplate
                                    "
                                >
                                    <div class="img-container">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Flag_of_Great_Britain_%281707%E2%80%931800%29.svg/1280px-Flag_of_Great_Britain_%281707%E2%80%931800%29.svg.png"
                                            alt=""
                                            class="nav-img"
                                        />
                                    </div>
                                </ng-container>
                                <ng-template #englishTemplate>
                                    <div class="img-container">
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_Cambodia.svg/1200px-Flag_of_Cambodia.svg.png"
                                            alt=""
                                            class="nav-img"
                                        />
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <mat-menu
                            #languageMenu="matMenu"
                            class="custom-menu"
                            xPosition="before"
                        >
                            <button
                                mat-menu-item
                                (click)="switchLanguage('en')"
                                class="btn-flag"
                            >
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Flag_of_Great_Britain_%281707%E2%80%931800%29.svg/1280px-Flag_of_Great_Britain_%281707%E2%80%931800%29.svg.png"
                                    alt=""
                                    class="img"
                                />
                                <span>English</span>
                            </button>
                            <button
                                mat-menu-item
                                (click)="switchLanguage('km')"
                                class="btn-flag"
                            >
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_Cambodia.svg/1200px-Flag_of_Cambodia.svg.png"
                                    alt=""
                                    class="img"
                                />
                                <span>Khmer</span>
                            </button>
                        </mat-menu>
                    </div>

                    <!-- profile -->
                    <div class="profile-box d-flex flex-row align-items-center">
                        <div class="profile" [matMenuTriggerFor]="profileMenu">
                            <img
                                src="assets/images/user.svg"
                                alt="profile"
                                srcset=""
                            />
                        </div>

                        <mat-menu #profileMenu="matMenu" class="custom-menu">
                            <button
                                mat-menu-item
                                (click)="userChangePwdHandler()"
                            >
                                <mat-icon>lock_reset</mat-icon>
                                <span>{{
                                    "actions.change_pwd" | translate
                                }}</span>
                            </button>
                            <button
                                mat-menu-item
                                (click)="logoutHandler()"
                                class="error"
                            >
                                <mat-icon>logout</mat-icon>
                                <span>{{ "actions.logout" | translate }}</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </mat-toolbar>
            <div class="app-container">
                <router-outlet />
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
