import { Component, HostListener, OnInit } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MENUITEMS } from '../../app.routes';
import { MenuItem } from '../../models/menu-item';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '../../services/auth.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { LocalStorageEnum } from '../../models/enums/local-storage-enum';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { BaseDialogData } from '../../models/bases/base-dialog-data';
import { DomSanitizer } from '@angular/platform-browser';
import { UserChangePwdFormComponent } from '../../routes/users/components/user-change-pwd-form/user-change-pwd-form.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-main-container',
    standalone: true,
    imports: [
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,
        RouterModule,
        TranslateModule,
        MatExpansionModule,
        MatListModule,
        MatMenuModule,
        NgIf,
    ],
    templateUrl: './main-container.component.html',
    styleUrl: './main-container.component.scss',
})
export class MainContainerComponent implements OnInit {
    currentLanguage: string = 'Khmer'; // Default language
    username: string;
    opened: boolean = true;
    menu: any = {};
    settingsMenu: MenuItem[];
    desktopViewWidth: number = 1100;
    drawerMode: 'over' | 'side' = 'side';
    @HostListener('window:resize', ['$event.target.innerWidth'])
    onResize(width: number) {
        if (width < this.desktopViewWidth) {
            this.drawerMode = 'over';
            if (this.opened) {
                this.opened = false;
            }
        } else {
            this.drawerMode = 'side';
            if (!this.opened) {
                this.opened = true;
            }
        }
    }

    constructor(
        iconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
        private _authService: AuthService,
        private _localStorageService: LocalStorageService,
        private _router: Router,
        private _dialog: MatDialog,
        private translate: TranslateService
    ) {
        iconRegistry.setDefaultFontSetClass('material-icons-outlined');
        iconRegistry.addSvgIcon(
            'home',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/home.svg')
        );

        iconRegistry.addSvgIcon(
            'record',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/record.svg')
        );

        iconRegistry.addSvgIcon(
            'carbon_report',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/carbon_report.svg'
            )
        );

        iconRegistry.addSvgIcon(
            'settings',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/settings.svg'
            )
        );
        iconRegistry.addSvgIcon(
            'chevron-down',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/chevron-down.svg'
            )
        );
        iconRegistry.addSvgIcon(
            'riel',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/riel.svg')
        );
        iconRegistry.addSvgIcon(
            'pdf',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/pdf.svg')
        );
    }

    ngOnInit(): void {
        this.username = this._localStorageService.get(
            LocalStorageEnum.username
        );
        const savedLanguage = this._localStorageService.get(
            LocalStorageEnum.language
        );

        this.currentLanguage = savedLanguage === 'km' ? 'Khmer' : 'English';
        this.translate.use(savedLanguage || 'en');
        this.username = this._localStorageService.get(
            LocalStorageEnum.username
        );
        const permissions = this._authService.permissions;
        this.menu = {
            // setting
            settings: MENUITEMS.filter((menuItem: MenuItem) => {
                return (
                    menuItem.type === 'settings' &&
                    menuItem.roles.includes(
                        this._localStorageService.get(
                            LocalStorageEnum.user_role
                        )
                    )
                );
            }),

            // dashboard
            dashboard: MENUITEMS.filter((menuItem: MenuItem) => {
                return (
                    menuItem.type === 'dashboard' &&
                    menuItem.roles.includes(
                        this._localStorageService.get(
                            LocalStorageEnum.user_role
                        )
                    )
                );
            }),

            // expense record
            expense_record: MENUITEMS.filter((menuItem: MenuItem) => {
                return (
                    menuItem.type === 'expense_record' &&
                    menuItem.roles.includes(
                        this._localStorageService.get(
                            LocalStorageEnum.user_role
                        )
                    )
                );
            }),

            // user
            users: MENUITEMS.filter((menuItem: MenuItem) => {
                return (
                    menuItem.type === 'users' &&
                    menuItem.roles.includes(
                        this._localStorageService.get(
                            LocalStorageEnum.user_role
                        )
                    )
                );
            }),
        };
        if (this._router.url === '/') {
            const firstAvailableMenu = MENUITEMS.find((item: MenuItem) => {
                return item.permissions?.find((permission) =>
                    permissions.includes(permission)
                );
            });
            if (firstAvailableMenu) {
                console.log('first available', firstAvailableMenu);

                this._router.navigate([firstAvailableMenu.route], {
                    replaceUrl: true,
                });
            } else {
                this._router.navigate(['/login'], {
                    queryParams: {
                        errors: 'UnauthorizedPermission',
                    },
                });
            }
        }
    }

    toggleMenu() {
        this.opened = !this.opened;
    }

    userChangePwdHandler = async () => {
        try {
            const dialogData: BaseDialogData = {
                isForUpdate: false,
            };
            const dialogRef = this._dialog.open(UserChangePwdFormComponent, {
                width: '600px',
                disableClose: true,
                data: dialogData,
                panelClass: 'custom-dialog',
            });
            const result = await lastValueFrom(dialogRef.afterClosed());
            if (result && result.succeeded) {
                console.log('userChangePwd succeeded => ', result.succeeded);
                this._authService.logout();
            } else {
                console.log('user change pwd error');
            }
        } catch (error) {
            // this._messageService.showError(error);
            console.log('user change pwd error->', error);
        }
    };
    logoutHandler() {
        ///TODO not yet implement

        this._authService.logout();
    }

    switchLanguage(language: string): void {
        if (language === 'en') {
            this.currentLanguage = 'English';
        } else if (language === 'km') {
            this.currentLanguage = 'Khmer';
        }
        this.translate.use(language);
        this._localStorageService.set(LocalStorageEnum.language, language);
    }
}
