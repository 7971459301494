import { Routes } from "@angular/router";
import { MainContainerComponent } from "./components/main-container/main-container.component";
import { MenuItem } from "./models/menu-item";
import { PermissionEnum } from "./models/enums/permission-enum";
import { noNeedLoginGuard } from "./guards/no-need-login.guard";
import { UserRoleEnum } from "./models/enums/user-role-enum";

export const routes: Routes = [
	{
		path: "",
		component: MainContainerComponent,
		children: [

			{
				path: "dashboard",
				data: {
					type: "dashboard",
					isSub: false,
					roles: [UserRoleEnum.USER],
				},

				loadChildren: () =>
					import("./routes/dashboard/dashboard.module").then(
						(m) => m.DashboardModule,
					),
			},

			{
				path: "expense-record",
				data: {
					type: "expense_record",
					isSub: false,
					roles: [UserRoleEnum.USER],
				},

				loadChildren: () =>
					import("./routes/expense-record/expense-record.module").then(
						(m) => m.ExpenseRecordModule,
					),
			},

			{
				path: "settings/expense-types",
				data: {
					type: "settings",
					isSub: true,
					roles: [UserRoleEnum.USER],
				},

				loadChildren: () =>
					import("./routes/expense-types/expense-types.module").then(
						(m) => m.ExpenseTypesModule,
					),
			},

			{
				path: "settings/budget",
				data: {
					type: "settings",
					isSub: true,
					roles: [UserRoleEnum.USER],
				},

				loadChildren: () =>
					import("./routes/budget/budget.module").then((m) => m.BudgetModule),
			},

			{
				path: "settings/reports",
				data: {
					type: "settings",
					isSub: true,
					roles: [UserRoleEnum.USER],
				},

				loadChildren: () =>
					import("./routes/reports/reports.module").then(
						(m) => m.ReportsModule,
					),
			},

			{
				path: "users",
				data: {
					type: "users",
					isSub: false,
					roles: [UserRoleEnum.ADMIN],
				},
				loadChildren: () =>
					import("./routes/users/users.module").then((m) => m.UsersModule),
			},
		],
	},

	{
		path: "login",
		canActivate: [noNeedLoginGuard],
		loadChildren: () =>
			import("./routes/login/login.module").then((m) => m.LoginModule),
	},
];

export const MENUITEMS: Array<MenuItem> = routes[0].children!.map(
	(routeItem) => {
		return {
			route: routeItem.path,
			type: routeItem.data?.type,
			permissions: routeItem.data?.permissions as PermissionEnum[],
			title: routeItem.path.split("/").pop(),
			icon: routeItem.data?.icon,
			iconType: routeItem.data?.iconType,
			isSub: routeItem.data?.isSub,
			roles: routeItem.data?.roles,
		} as MenuItem;
	},
);
